import { Injectable } from '@angular/core';
import { BaseService } from '../classes/base.service';
import { CacheService } from './cache.service';
import { PageService } from './page.service';

@Injectable()
export class UserService extends BaseService {

  private token: string | undefined;

  private username: string;

  private islogin: boolean = false;

  private trusteddevicetoken: string;

  constructor(private cachesvr: CacheService) {
    super();
    this.tryLogin();
  }

  public setLogin(data?: any) {
    this.trusteddevicetoken = data?.trusted_device_token
    this.islogin = true;
    this.token = data.token;
    this.username = data.user.name;
    this.cachesvr.set("userdata", data);
  }

  public tryLogin() {
    let obj: any = this.cachesvr.get("userdata");
    if (obj) {
      console.log('obj', obj);
      this.setLogin(obj);
    }
  }

  public logout() {
    this.cachesvr.delete("userdata");
    this.token = undefined;
    this.islogin = false;
  }

  public isLogin(): boolean {
    return this.islogin;
  }

  public getLoginUsername(): string {
    return this.username;
  }

  public getToken(): string | undefined {
    return this.token;
  }

  public defaultHomePageRoute(defaultpageurl?: string): string {
    return defaultpageurl ? defaultpageurl : "/provider/dashboard";
  }

  public setAuthTrustedDeviceToken(token: string) {
    this.cachesvr.set("auth_trusted_device_token", token);
  }

  public getAuthTrustedDeviceToken() {
    return this.cachesvr.get("auth_trusted_device_token");
  }

  public signInRoute(): string {
    return "auth/signin";
  }
}
