import { Injectable } from '@angular/core';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {
  showSuccess(message: string, title: string = 'Success') {
    Swal.fire({
      title: title,
      text: message,
      icon: 'success',
      confirmButtonText: 'OK'
    });
  }

  showError(message: string, title: string = 'Error') {
    Swal.fire({
      title: title,
      text: message,
      icon: 'error',
      confirmButtonText: 'OK'
    });
  }

  showConfirmation(message: string, title: string = 'Are you sure?', icon: any = ''): Promise<boolean> {
    return Swal.fire({
      title: title,
      text: message,
      icon: icon,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        popup: 'swl-alert-dialogue',
        title: 'swl-alert-title',
        confirmButton: 'swl-btn btn btn-secondry btn-icon',
        cancelButton: 'swl-btn swl-btn-cancel btn btn-secondry btn-icon',
        icon: 'swl-icon'
      }
    }).then(result => result.isConfirmed);
  }

  showAlert(options: SweetAlertOptions) {
    return Swal.fire(options);
  }
}
