import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import AppHelper from "../helpers/apphelper";
import { BaseService } from '../classes/base.service';
import { CacheService } from './cache.service';

@Injectable()

export class NavigationService extends BaseService {

    public redirectafterloginurl: string;
    public selectedNav: any;

    constructor(private router: Router, private cache: CacheService) {
        super();
    }
    private resetService() {
        this.selectedNav = undefined;
    }

    route(route: string) {
        this.router.navigate([route]);
    }

    route1(route: string, obj: any) {
        this.router.navigate([route, obj]);
    }

    route2(route: string, params: string[]) {
        this.router.navigate([route, ...params]);
    }

    routeUnique(route: string, data: any = undefined) {
        let key = AppHelper.getID();
        this.cache.set(key, data);
        this.router.navigate([route, key]);
    }

    getUniqueParams(keyid: string) {
        return this.cache.get(keyid);
    }
}
