import { Injectable } from '@angular/core'
import { BaseService } from '../classes/base.service';


@Injectable()
export class CacheService extends BaseService {
    data: { [key: string]: any } = {};

    public override clearSessionData(): void {
        this.data = {};
        sessionStorage.clear();
        console.log("cache clear");
    }

    public set(id: string, value: any, usesessionstorage: boolean = true) {
        this.data[id] = value;
        if (usesessionstorage) {
            if (value == undefined) {
                this.delete(id);
            }
            else {
                sessionStorage.setItem(id, JSON.stringify(value));
            }
        }
    }

    public get(id: string): any {
        let obj: any = sessionStorage.getItem(id);
        return this.data[id] ? this.data[id] : JSON.parse(obj);
    }

    public delete(id: string) {
        this.data[id] = undefined;
        sessionStorage.removeItem(id);
    }
}
