import { AfterViewInit, Component, Directive, Inject, OnDestroy, OnInit, inject } from "@angular/core";
import { BaseSubView } from "./basesubview";
import { PageService } from "../service/page.service";
import { NavigationService } from "../service/navigate.service";
import { UserService } from "../service/user.service";


@Component({
    selector: 'app-baseview',
    template: ``,
    standalone: true,
})
export abstract class BaseView extends BaseSubView {

    private pageservice = inject(PageService);

    private userservice = inject(UserService);

    constructor() {
        super();
        this.pageservice.resetView(this);
    }

    setShowHideSideBar(sidebarVisible: boolean) {
        this.pageservice.setShowHideSideBar(sidebarVisible);
    }

    isSideBarShow(): boolean {
        return this.pageservice.isSideBarShow()
    }

    getUniqueParams(keyid: string) {
        return this.pageservice.navservice.getUniqueParams(keyid);
    }

    getbackgroundImageUrl(): string {
        return this.pageservice.getbackgroundImageUrl();
    }

    getPageService(): PageService {
        return this.pageservice;
    }

    getNavService(): NavigationService {
        return this.pageservice.navservice;
    }

    getSelectedNav() {
        return this.pageservice.getSelectedNav();
    }

    setSelectedNav(nav: string) {
        this.pageservice.setSelectedNav(nav);
    }

    public showSuccessMsg(msg: string, summary: string = "Success") {
        this.pageservice.showSuccessMsg(msg, summary)
    }

    public showErrorMsg(msg: string, summary: string = "Failure") {
        this.pageservice.showErrorMsg(msg, summary)
    }

    showInfoMsg(msg: string, summary: string = "Information") {
        this.pageservice.showInfoMsg(msg, summary)
    }

    getLoginUsername() {
        return this.userservice.getLoginUsername()
    }

    isLogin() {
        return this.userservice.isLogin();
    }

    isPageBusy(): boolean {
        return this.isCompBusy();
    }

    isPageReady(): boolean {
        return this.isViewReady();
    }

    setPageReady() {
        this.setViewReady();
    }

    setPageBusy() {
        this.setCompBusy();
    }

    setPageNotBusy() {
        this.setCompNotBusy();
    }

    setbackgroundImageUrl(url: string) {
        this.pageservice.setbackgroundImageUrl(url);
    }
}
