import { Inject, Injectable, inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BaseService } from '../classes/base.service';
import { NavigationService } from './navigate.service';
import { CacheService } from './cache.service';
import { EventService } from './event.service';
import { SweetAlertService } from './sweet-alert.service';
import { BaseView } from '../classes/baseview';
import { UserService } from './user.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class PageService {

  public navservice = inject(NavigationService);

  public messageService = inject(MessageService);

  public cacheservice = inject(CacheService);

  public userservice = inject(UserService);

  // public evcentservice = inject(EventService);

  private msg_display_time: number = 5000;

  private msg_dict: any = {};

  public sweetAlertService = inject(SweetAlertService)

  private _backgroundImageUrl: string;

  pageFault: any = undefined;

  private baseview: BaseView;

  constructor() {
  }

  resetView(baseview: BaseView) {
    console.log('reset view');
    this.pageFault = undefined;
    this.baseview = baseview;
  }

  isPageReady() {
    return this.baseview.isViewReady();
  }

  isPageBusy(): boolean {
    return this.baseview.isCompBusy();
  }

  setbackgroundImageUrl(url: string) {
    this._backgroundImageUrl = url;
  }

  getbackgroundImageUrl(): string {
    return this._backgroundImageUrl;
  }

  setPageFault(msg: string = "Error occured while processing request.") {
    this.pageFault = msg;
    this.baseview.setCompNotBusy();
  }

  public showSuccessMsg(msg: string, summary: string = "Success") {
    this.addMessage(msg, summary, 'success');
  }

  public showErrorMsg(msg: string, summary: string = "Failure", severity: string = 'error', id: string = 'toast-error-msg') {
    this.addMessage(msg, summary, severity, id);
  }

  public showInfoMsg(msg: string, summary: string = "Information") {
    this.addMessage(msg, summary, 'info');
  }

  private addMessage(msg: string, summary: string, severity: string, id: string = '', messageTime: number = 0) {
    if (this.msg_dict[msg] === true) {
      return;
    }

    let iMessageTime = 0;
    if (messageTime)
      iMessageTime = messageTime;
    else
      messageTime = this.msg_display_time;

    this.msg_dict[msg] = true;
    setTimeout(() => {
      this.msg_dict[msg] = false;
    }, this.msg_display_time)

    this.messageService.add({
      life: messageTime, closable: true, sticky: false, severity: severity, summary: summary, detail: msg, id: id
    });
  }

  public sidebarVisible: boolean = false;

  setShowHideSideBar(sidebarVisible: boolean) {
    this.sidebarVisible = sidebarVisible;
  }

  isSideBarShow(): boolean {
    return this.sidebarVisible
  }

  setSelectedNav(nav: string) {
    this.navservice.selectedNav = nav;
  }

  getSelectedNav() {
    return this.navservice.selectedNav;
  }
}
